import { Box, Grid } from "@mui/material";

import IntegrationTable from "./components/Integrations";
import ReposTable from "./components/Repos";
import UsersTable from "./components/Users";
import { useSearchParams } from "react-router-dom";

const Settings = () => {
  const [searchParams] = useSearchParams();
  const selectedIntegration = searchParams.get("integration");
  return (
    <Box sx={{ height: "100vh", overflowY: "auto" }}>
      <Grid container spacing={2} sx={{ padding: "20px", overflowY: "auto" }}>
        <Grid item xs={12}>
          <IntegrationTable selectedIntegration={selectedIntegration} />
        </Grid>
        <Grid item xs={12}>
          <ReposTable />
        </Grid>
        <Grid item xs={12}>
          <UsersTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Settings;
