import React, { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

const PartyPopper: React.FC = () => {
  const [isPopping, setIsPopping] = useState(false);

  useEffect(() => {
    setIsPopping(true);
    setTimeout(() => {
      setIsPopping(false);
    }, 2000);
  }, []);

  return (
    <>{isPopping && <ConfettiExplosion />}</>
  );
};

export default PartyPopper;