import React, { useEffect, useState, useContext } from 'react';
import { Typography, Grid, Box, Button, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Context } from '@/contexts/ContextProvider';
import PartyPopper from '@/components/Animations/Partypopper';
import { create_subscription, get_subscription } from '@/services/Blar/Subscription';
import { Subscription } from './types';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { LinearProgress } from '@mui/material';

interface PricingCardProps {
  title: string;
  price: string | number;
  features: string[];
  isSubscribed: boolean;
  onSubscribe: () => void;
  isCustom?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({ 
  title, 
  price, 
  features, 
  isSubscribed, 
  onSubscribe,
  isCustom = false
}) => (
  <Paper elevation={3} sx={{
    p: 3,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: isSubscribed ? '2px solid #1976d2' : 'none',
    position: 'relative',
    borderRadius: '20px'
  }}>
    <Typography variant='h5' component='h2' gutterBottom>
      {title}
    </Typography>
    {isCustom ? 
      <Typography variant='h4' component='div' gutterBottom>
        Contact Us
      </Typography> :
      <Typography variant='h4' component='div' gutterBottom>
        ${price}<Typography variant='subtitle1' component='span'>/user</Typography>
      </Typography>
    }
    
    <Button 
      variant='contained'
      color='primary'
      fullWidth
      onClick={onSubscribe}
      disabled={isSubscribed}
    >
      {isSubscribed ? 'Current Plan' : 'Subscribe'}
    </Button>
    <List sx={{ flexGrow: 1 }}>
      {features.map((feature, index) => (
        <ListItem key={index} dense>
          <ListItemIcon>
            <CheckIcon color='primary' />
          </ListItemIcon>
          <ListItemText primary={feature} />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const Subscriptions = () => {
  const { showMessage, companyTier } = useContext(Context);
  const [subscription, setSubcription] = useState<null | Subscription>(null);
  const [loading, setLoading] = useState<boolean>(false);
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentSuccess = queryParams.get('payment');

  useEffect(() => {
    async function fetchSubscriptionData() {
      try {
        setLoading(true);
        const subscriptionResponse = await get_subscription();
        if (subscriptionResponse.status === 200) {
          setSubcription({
            status: subscriptionResponse.data.subscription.status,
            currentPeriodStart: subscriptionResponse.data.subscription.current_period_start,
            currentPeriodEnd: subscriptionResponse.data.subscription.current_period_end,
            quantity: subscriptionResponse.data.subscription.quantity,
          });
        }
      } catch (error: any) {
        if (error instanceof AxiosError) {
          const response = error.response;
          if (response?.status === 404) {
            return;
          }
        }
        showMessage('error', `Failed to fetch subscription`);
      } finally {
        setLoading(false);
      }
    }
    fetchSubscriptionData();
  }, []);

  const handleSubscribe = async () => {
    const retrieve_session_url = await create_subscription();
    if (retrieve_session_url.status === 201) {
      window.location.href = retrieve_session_url.data.session_url;
    } else {
      showMessage('error', 'Failed to create subscription');
    }
  }

  const scheduleMetting = () => {
    window.location.href = 'https://calendly.com/jose-wsa/30min';
  }

  return (
    <>
    {loading && (
        <LinearProgress 
          sx={{ 
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 9999
          }} 
        />
      )}
      <br/>
      <Typography variant='h4' gutterBottom align='center' sx={{ mb: 4, mt: 3 }}>
        Choose Your Plan
      </Typography>
      {subscription && paymentSuccess && <PartyPopper />}
      <Grid container spacing={4} justifyContent='center' p={10}>
        <Grid item xs={12} md={4}>
          <PricingCard
            title='Free Trial'
            price='0'
            features={[
              'Up to 10 PR',
              'One user',
              'One week trial',
              'One repository',
              'One branch'
            ]}
            isSubscribed={companyTier === 1}
            onSubscribe={() => {}}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PricingCard
            title='Teams'
            price='10'
            features={[
              'Unlimited PR',
              'Pay per seat',
              'Monthly subscription',
              'Unlimited chats',
              'Up to 5 repositories',
              'Up to 3 branches by repository',
              'Monitoring tool integration'
            ]}
            isSubscribed={companyTier === 2}
            onSubscribe={handleSubscribe}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PricingCard
            title='Enterprise'
            price='Custom'
            features={[
              'Everything on Teams plan',
              'On-premise solution',
              'Custom integrations',
              'Custom code languages support',
              'Premium support'
            ]}
            isSubscribed={companyTier === 3}
            onSubscribe={scheduleMetting}
            isCustom={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Subscriptions;