import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import { Context } from "@/contexts/ContextProvider";
import { Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import FilterNoneOutlinedIcon from '@mui/icons-material/FilterNoneOutlined';

interface MoreMenuProps {
  setSettingsAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}

const MoreMenu: React.FC<MoreMenuProps> = ({ setSettingsAnchorEl }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { signOut } = useContext(Context);

  React.useState<null | HTMLElement>(null);

  const navigateTo = (selected: string) => {
    switch (selected) {
      case "settings":
        setSettingsAnchorEl(null);
        navigate("/settings");
        break;
      case "billing":
        setSettingsAnchorEl(null);
        navigate("/subscribtion");
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    setSettingsAnchorEl(null);

    signOut();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "start",
      }}
    >
      <Button
        size="small"
        variant="outlined"
        sx={{ 
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        onClick={() => navigateTo("settings")}
        color="blarWhite"
        startIcon={<SettingsOutlinedIcon />}
      >
        <Typography variant="body1">Settings</Typography>
      </Button>
      <Button
        size="small"
        variant="outlined"
        sx={{ 
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        onClick={() => navigateTo("billing")}
        color="blarWhite"
        startIcon={<FilterNoneOutlinedIcon />}
      >
        <Typography variant="body1">Plans & Pricing</Typography>
      </Button>
      <Button
        size="small"
        sx={{
          width: "fit-content", 
          border: "1px solid",
          borderColor: theme.palette.divider,
        }}
        variant="outlined"
        onClick={handleLogout}
        color="blarWhite"
        startIcon={<LogoutIcon />}
      >
        <Typography variant="body1">Logout</Typography>
      </Button>
    </Box>
  );
};

export default MoreMenu;
