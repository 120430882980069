import React, {
  useEffect,
  useState,
  MouseEvent,
  useRef,
  useContext,
} from "react";
import ReactFlow, { Node as FlowNode, Edge as FlowEdge } from "reactflow";

import Node from "./CustomNode";
import Edge from "./CustomEdge";
import "reactflow/dist/style.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  get_max_level,
  list_node,
  get_node,
} from "../../services/Blar/Repo_graph";
import {
  EdgesListOutput,
  LevelData,
  NodesListOutput,
  GetNodeOutput,
} from "../../interfaces/IBackendOutputs";
import FilterPanel from "./PanelComponents/Panels";
import { GraphFilter } from "../../interfaces/IFilters";
import { Context } from "@/contexts/ContextProvider";
import { EdgeDictInterface } from "./types";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const nodeTypes = {
  zoom: Node,
};

const edgeTypes = {
  zoom: Edge,
};

interface CustomNodeData {
  level: number;
  name: string;
  countOfDescendants: number;
  children: ChildNode[];
  leaf: boolean;
  max_level: number;
  size: number;
  labels: string[];
  impact: number;
  highlightNode: string | undefined;
}

const loadingMessages = [
  "Fetching your graph...",
  "Building your graph...",
  "Connecting the nodes...",
  "Almost there...",
  "Loading...",
];

export const Graph: React.FC = () => {
  const { showMessage } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const [graphData, setGraphData] = useState<{
    nodes: FlowNode[];
    edges: FlowEdge[];
  }>({ nodes: [], edges: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [levelsDict, setLevelsDict] = useState<{
    [key: string]: LevelData;
  } | null>(null);
  const [nodeInfoToShow, setNodeInfoToShow] = useState<
    GetNodeOutput | undefined
  >(undefined);
  const [loadingNodeInfo, setLoadingNodeInfo] = useState<boolean>(false);
  const [maxLevel, setMaxLevel] = useState<number>(0);
  const [selectedFilter, setSelectedFilter] = useState<GraphFilter>({
    type: "Files",
  });
  const [highlightNode, setHightlightNode] = useState<string | undefined>(
    undefined
  );
  const [highlightedPath, setHighlightedPath] = useState<string[]>([]);
  const [highlightedEdges, setHighlightedEdges] = useState<string[]>([]);
  const [selectedGraphVideo, setSelectedGraphVideo] = useState<null | number>(
    null
  );
  const [edgesDict, setEdgesDict] = useState<EdgeDictInterface>({});
  const [loadingMessage, setLoadingMessage] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [origin, setOrigin] = useState<string | null>(null);

  const handlePaneClick = () => {
    setNodeInfoToShow(undefined);
    setHighlightedEdges([]);
    setHightlightNode(undefined);
    setHighlightedPath([]);
    setSelectedGraphVideo(null);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const videoId = queryParams.get("videoId");
    const origin = queryParams.get("origin");

    if (videoId) {
      setSelectedGraphVideo(Number(videoId));
    }
    if (origin) {
      setOrigin(origin);
    }
  }, [location.search]);

  useEffect(() => {
    if (highlightedPath.length === 0) return;
    let newHighlightedEdges: string[] = [];
    for (let i = 1; i < highlightedPath.length; i++) {
      const currentNode = highlightedPath[i];
      for (let j = 0; j < i; j++) {
        const previousNode = highlightedPath[j];

        if (edgesDict[previousNode] && edgesDict[previousNode][currentNode]) {
          newHighlightedEdges = [
            ...newHighlightedEdges,
            edgesDict[previousNode][currentNode],
          ];
        }
      }
    }
    setHighlightedEdges(newHighlightedEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedPath, highlightNode]);

  useEffect(() => {
    const buildEdgesDict = (edges: EdgesListOutput[]) => {
      const newEdgesDict: { [key: string]: { [key: string]: string } } = {};
      edges.forEach((edge: EdgesListOutput) => {
        newEdgesDict[edge.source] = newEdgesDict[edge.source] || {};
        newEdgesDict[edge.source][
          edge.target
        ] = `${edge.source}-${edge.target}-${edge.type}`;
      });
      return newEdgesDict;
    };

    const fetchNodes = async () => {
      setLoading(true);
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(async () => {
        try {
          const randomIndex = Math.floor(
            Math.random() * loadingMessages.length
          );
          setLoadingMessage(randomIndex);
          const pollResponse = await list_node();
          const pollNodesGroupByLevel = pollResponse.result;
          const pollIsSyncing = pollResponse.is_syncing;

          if (!pollIsSyncing) {
            clearInterval(intervalRef.current!);
            setLevelsDict(pollNodesGroupByLevel);
            let pollUpdatedEdgesDict: EdgeDictInterface = {};
            for (let levelToShow of Object.keys(pollNodesGroupByLevel)) {
              if (levelToShow === "null") {
                continue;
              }
              const newEdge = buildEdgesDict(
                pollNodesGroupByLevel[levelToShow].edges
              );
              pollUpdatedEdgesDict = { ...pollUpdatedEdgesDict, ...newEdge };
            }
            setEdgesDict(pollUpdatedEdgesDict);
          }
        } catch (error: any) {
          if (error.response?.status === 404) {
            setGraphData({ nodes: [], edges: [] });
            setLoading(false);
            if (intervalRef.current) clearInterval(intervalRef.current);
            showMessage("warning", `No code information found`);
            return;
          }
          showMessage("error", `Failed to fetch graph information`);
        }
      }, 5000); // Poll every 5 seconds
    };

    const fetchMaxLevel = async () => {
      try {
        const response = await get_max_level();
        setMaxLevel(response.data.max_level);
      } catch (error: any) {
        if (error.response?.status === 404) {
          return;
        }
        showMessage("error", `Failed to fetch graph information`);
      }
    };

    fetchMaxLevel();
    fetchNodes();

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const debouncedEffect = debounce(() => {
      const formatNodesAndEdges = (
        nodeLevelDict: { [key: string]: { level: number; leaf: boolean } },
        incomingEdges: EdgesListOutput[],
        incomingNodes: NodesListOutput[]
      ) => {
        const calls_edges = incomingEdges.filter(
          (edge: any) => edge.type === "CALLS"
        );

        const newNodes = incomingNodes.map((node: NodesListOutput) => {
          return {
            id: node.node_id,
            type: "zoom",
            data: {
              level: node.level,
              name: node.name,
              max_level: maxLevel,
              leaf: Boolean(node.leaf),
              size: node.size,
              labels: node.labels,
              selectedFilter: selectedFilter,
              impact: node.impact,
              highlightNode: highlightNode,
              highlightedPath: highlightedPath,
            },
            zIndex: node.level !== null ? node.level : 1,
            draggable: false,
            selectable: true,
            position: { x: node.x, y: node.y },
          } as FlowNode;
        });

        const newEdges = calls_edges
          .map((edge: any) => {
            const sourceNode = nodeLevelDict[edge.source];
            const targetNode = nodeLevelDict[edge.target];

            // Skip this edge if either source or target node is missing
            if (!sourceNode || !targetNode) {
              return null;
            }

            return {
              id: `${edge.source}-${edge.target}-${edge.type}`,
              source: edge.source,
              target: edge.target,
              label: edge.type,
              data: {
                targetLevel: targetNode.level,
                sourceLevel: sourceNode.level,
                sourceLeaf: sourceNode.leaf,
                targetLeaf: targetNode.leaf,
                latency: edge.latency || 0,
                selectedFilter: selectedFilter,
                highlightedEdges: highlightedEdges,
              },
              zIndex: targetNode.level,
              type: "zoom",
            } as FlowEdge;
          })
          .filter((edge): edge is FlowEdge => edge !== null);
        return { newNodes, newEdges };
      };

      if (!levelsDict) return;
      setLoading(true);
      let nodeLevelDict: { [key: string]: { level: number; leaf: boolean } } =
        {};

      // Create Node Level Dictionary
      for (let levelToshow of Object.keys(levelsDict)) {
        levelsDict[levelToshow].nodes.forEach((node: NodesListOutput) => {
          nodeLevelDict[node.node_id] = {
            level: node.level,
            leaf: Boolean(node.leaf),
          };
        });
      }

      let nodesToShow: FlowNode[] = [];
      let edgesToShow: FlowEdge[] = [];
      for (let levelToshow of Object.keys(levelsDict)) {
        if (levelToshow === "null") {
          continue;
        }
        const { newNodes, newEdges } = formatNodesAndEdges(
          nodeLevelDict,
          levelsDict[levelToshow].edges,
          levelsDict[levelToshow].nodes
        );

        nodesToShow = [...nodesToShow, ...newNodes];
        edgesToShow = [...edgesToShow, ...newEdges];
      }
      setGraphData({ nodes: nodesToShow, edges: edgesToShow });
      setLoading(false);
    }, 300); // Adjust debounce delay as needed (e.g., 300ms)

    debouncedEffect();
    return () => {
      debouncedEffect.cancel();
    };
  }, [
    levelsDict,
    maxLevel,
    selectedFilter,
    highlightNode,
    highlightedPath,
    highlightedEdges,
  ]);

  const onNodeClick = async (
    event: MouseEvent,
    node: FlowNode<CustomNodeData>
  ) => {
    const targetLabels = ["FOLDER", "PACKAGE"];
    const isFolderOrPackageNode = targetLabels.some((label) =>
      node.data.labels.includes(label)
    );
    if (isFolderOrPackageNode) return;

    const nodeId = node.id;
    await FetchNodeDetail(nodeId);
    setHightlightNode(nodeId);
  };

  const FetchNodeDetail = async (nodeId: string) => {
    try {
      setLoadingNodeInfo(true);
      const nodeInfo = await get_node(nodeId, "main");
      setNodeInfoToShow(nodeInfo);
      setLoadingNodeInfo(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    // Get the previous location (one step back)
    let previousPath = origin || -1;

    if (typeof previousPath === "string" && selectedGraphVideo !== null) {
      let url = new URL(previousPath, window.location.origin);
      url.searchParams.set("videoId", selectedGraphVideo?.toString() || "");
      navigate(url.pathname + url.search);
    } else {
      // If the previous path is not a valid string, just go back
      navigate(-1);
    }
  };
  return (
    <Box height={"100%"} ref={boxRef}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress size={80} />
          <Typography
            variant="h6"
            sx={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            {loadingMessages[loadingMessage]}
          </Typography>
        </Box>
      ) : (
        boxRef.current && (
          <ReactFlow
            nodes={graphData.nodes}
            edges={graphData.edges}
            minZoom={0.005}
            maxZoom={5}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodeOrigin={[0.5, 0.5]}
            preventScrolling={true}
            onlyRenderVisibleElements={true}
            onNodeClick={onNodeClick}
            onPaneClick={handlePaneClick}
            fitView={true}
            style={{ flexGrow: 1 }} // Ensure the graph takes up all available space
          >
            <FilterPanel
              setHightLightNode={setHightlightNode}
              FetchNodeDetail={FetchNodeDetail}
              nodeInfoToShow={nodeInfoToShow}
              loadingNodeInfo={loadingNodeInfo}
              selectedGraphVideo={selectedGraphVideo}
              setHighlightedPath={setHighlightedPath}
              handleGoBack={handleGoBack}
            />
          </ReactFlow>
        )
      )}
    </Box>
  );
};
