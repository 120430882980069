import React from "react";
import { Typography } from "@mui/material";
import BaseModal from "@/components/Modal/BaseModal";
import GitHubUserConfirmation from "@/pages/Auth/Onboarding/components/GitHubUserConfirmation";

interface GitHubUserLinkModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: string;
  setCreatedObject: (createdObject: number) => void;
}

const GitHubUserLinkModal: React.FC<GitHubUserLinkModalProps> = ({
  open,
  setOpen,
  userId,
  setCreatedObject,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Link GitHub User"
      style={{ width: "55%" }}
    >
      <Typography variant="subtitle1" sx={{ marginBottom: "20px" }}>
        Select a GitHub User to link to your account:
      </Typography>
      <GitHubUserConfirmation
        onConfirmation={() => {
          setOpen(false);
          setCreatedObject(Date.now());
        }}
        userId={userId}
      />
    </BaseModal>
  );
};

export default GitHubUserLinkModal;
