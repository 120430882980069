import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { check_integration_exists } from "@/services/Blar/Integrations";
import { list_repos } from "@/services/Blar/Repo_graph";
import handleGithubIntegration, {
  handleRedirectToGitHubApp,
} from "@/pages/Settings/components/Integrations/handleIntegrations/handleGithub";
import { Context } from "@/contexts/ContextProvider";
import { useSearchParams } from "react-router-dom";
import LoadingTypography from "./components/LoadingTypography";
import RepositoryBranchSelector from "@/components/Selectors/RepositoryBranchSelector";
import ContinueButton from "@/components/Buttons/ContinueButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import { IntegrationType } from "@/interfaces/IIntegration";
import useStyles from "./styles/Integrations";
import GitHubUserConfirmation from "./components/GitHubUserConfirmation";
import { fetchUserInfo } from "@/services/Blar/Auth";

interface CoreIntegrationProps {
  continueOnboarding: () => void;
}

const CoreIntegrationComponent: React.FC<CoreIntegrationProps> = ({
  continueOnboarding,
}) => {
  const classes = useStyles();
  const { showMessage, userId } = useContext(Context);
  const [searchParams] = useSearchParams();
  const [isGHConnected, setIsGHConnected] = useState<
    boolean | null | "pending"
  >(null);
  const [hasRepo, setHasRepo] = useState(false);
  const [loadingGithub, setLoadingGithub] = useState(false);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [reloadCheck, setReloadCheck] = useState(false);
  const [hasGitHubUser, setHasGitHubUser] = useState(false);

  const checkGHIntegrationExists = async (): Promise<boolean | "pending"> => {
    try {
      setLoadingGithub(true);

      const res = await check_integration_exists({
        source: IntegrationType.GITHUB,
      });
      setIsGHConnected(res.data);
    } catch (error) {
      showMessage("error", "Failed to get Sentry integration");
    } finally {
      setLoadingGithub(false);
    }

    return false;
  };
  const loadUserInfo = async () => {
    try {
      setLoadingUser(true);
      const users = await fetchUserInfo();
      if (users.external_profiles.length === 0) {
        setHasGitHubUser(false);
      } else {
        setHasGitHubUser(true);
      }
    } catch (error) {
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (isGHConnected) {
      checkIfReposExists();
      loadUserInfo();
    }
    if (isGHConnected !== null && !isGHConnected) {
      setLoadingGithub(true);
      handleGithubIntegration(showMessage, searchParams)
        .then(() => {
          setReloadCheck(true);
        })
        .finally(() => {
          setLoadingGithub(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGHConnected]);

  useEffect(() => {
    checkGHIntegrationExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCheck]);

  const checkIfReposExists = async () => {
    try {
      setLoadingRepos(true);

      const repoRequest = await list_repos();
      const repositories = repoRequest.data.results;

      if (repositories.length > 0) {
        setHasRepo(true);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  };

  return (
    <Box className={classes.root}>
      <LoadingTypography
        text="1. Connect to GitHub"
        loading={loadingGithub}
        stepCompleted={isGHConnected === true || false}
      />
      {isGHConnected === "pending" && (
        <Typography variant="body1" color="info" mb={2}>
          Waiting for GitHub approval, please ask your organization admin to
          approve the request.
        </Typography>
      )}
      {!isGHConnected && (
        <Box className={classes.connectButton}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<GitHubIcon />}
            style={{ fontSize: "20px" }}
            onClick={() => handleRedirectToGitHubApp(showMessage)}
            disabled={isGHConnected || false}
          >
            Connect
          </Button>
        </Box>
      )}
      <LoadingTypography
        text="2. Select your user"
        loading={loadingUser}
        stepCompleted={hasGitHubUser}
      />
      <Box className={classes.loggerToolBox}>
        {isGHConnected === true && !hasGitHubUser && (
          <GitHubUserConfirmation
            userId={userId!}
            onConfirmation={() => {
              setHasGitHubUser(true);
            }}
          />
        )}
      </Box>
      <LoadingTypography
        text="3. Select the repositories"
        loading={loadingRepos}
        stepCompleted={hasRepo}
      />
      <Box className={classes.loggerToolBox}>
        {isGHConnected === true && !hasRepo && (
          <RepositoryBranchSelector setHasRepo={setHasRepo} />
        )}
      </Box>
      <ContinueButton
        continueOnboarding={continueOnboarding}
        disabled={!(isGHConnected && hasRepo)}
      />
    </Box>
  );
};

export default CoreIntegrationComponent;
