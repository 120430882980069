import { GitHubUser } from "@/pages/Auth/types/common";
import { blarClient } from "./Client";

interface linkGithubUserParams {
  user_id: string;
  external_profile: GitHubUser;
}

export const list_github_user = async (params: linkGithubUserParams) => {
  console.log("Linking GitHub user:", params);
  try {
    const response = await blarClient.request(
      "post",
      "/integrations/github/link_user",
      params
    );
    return response.data.results;
  } catch (error) {
    console.error("Error linking GitHub user:", error);
    throw error;
  }
};
