import React, { useState, useEffect, useContext } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import GitHubIcon from "@mui/icons-material/GitHub";
import { fetchGithubMembers } from "@/services/Blar/Auth";
import { GitHubUser } from "../../types/common";
import { Context } from "@/contexts/ContextProvider";
import { list_github_user } from "@/services/Blar/Github";

interface GitHubUserConfirmationProps {
  onConfirmation: () => void;
  userId: string;
}

const GitHubUserConfirmation: React.FC<GitHubUserConfirmationProps> = ({
  onConfirmation,
  userId,
}) => {
  const [githubUsers, setGithubUsers] = useState<GitHubUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<GitHubUser | null>(null);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useContext(Context);

  useEffect(() => {
    const loadGithubMembers = async () => {
      setLoading(true);
      try {
        const users = await fetchGithubMembers();
        setGithubUsers(users);
      } catch (error) {
        console.error("Failed to fetch GitHub usernames:", error);
      } finally {
        setLoading(false);
      }
    };

    loadGithubMembers();
  }, []);

  const onUserConfirmed = (user: GitHubUser | null) => {
    async function linkUser() {
      try {
        setLoading(true);

        const params = {
          user_id: userId,
          external_profile: user!,
        };
        await list_github_user(params);
        showMessage("success", `User Linked Successfully`);
        onConfirmation();
      } catch (error: any) {
        console.error("Error linking GitHub user:", error);
        if (error?.response?.status === 400) {
          showMessage("error", error?.response?.data[0]);
        } else {
          showMessage("error", "Failed to link user. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    }
    if (!user) {
      return;
    }
    linkUser();
  };

  return (
    <Box>
      <Autocomplete
        options={githubUsers}
        loading={loading}
        getOptionLabel={(option) => option.login || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose your GitHub User"
            InputProps={{
              ...params.InputProps,
              sx: { borderRadius: "10px" },
            }}
          />
        )}
        onChange={(event, newValue) => setSelectedUser(newValue || null)}
        value={selectedUser || null}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <LoadingButton
          variant="contained"
          color="secondary"
          startIcon={<GitHubIcon />}
          style={{ fontSize: "20px", marginTop: "10px" }}
          onClick={() => onUserConfirmed(selectedUser || null)}
          disabled={selectedUser === null || selectedUser === undefined}
        >
          Confirm GitHub User
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default GitHubUserConfirmation;
