import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import GitHubUserLinkModal from "./LinkUserGithubModal";
type LinkGithubUserProps = {
  userId: string;
  setCreatedObject: (createdObject: number) => void;
};

const LinkGithubUser = ({ userId, setCreatedObject }: LinkGithubUserProps) => {
  const [open, setOpen] = useState(false);
  const handleOpenAddGithubModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Tooltip title="Link Github User">
          <IconButton onClick={handleOpenAddGithubModal}>
            <AddIcon fontSize="small" sx={{ color: "#1a7f64" }} />
          </IconButton>
        </Tooltip>
      </div>
      <GitHubUserLinkModal
        open={open}
        setOpen={setOpen}
        userId={userId}
        setCreatedObject={setCreatedObject}
      />
    </>
  );
};

export default LinkGithubUser;
