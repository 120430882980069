import { GitHubUser } from "@/pages/Auth/types/common";
import { blarClient } from "./Client";
import { User } from "@/interfaces/ICompany";

interface BusinessSignUpParams {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name?: string;
  token?: string;
  external_profile?: GitHubUser | null;
}

export const serverSignIn = async (email: string, password: string) => {
  return blarClient.request("post", "/auth/login/", { email, password });
};

export const serverGuestSignIn = async (email: string) => {
  return blarClient.request("post", "/auth/login/guest", { email });
};

export const serverRefresh = async (refresh: string) => {
  return blarClient.request("post", "/auth/refresh/", { refresh });
};

export const serverSignUp = async (params: BusinessSignUpParams) => {
  return blarClient.request("post", "/users/register", {
    ...params,
    password2: params.password,
  });
};

export const fetchGithubMembers = async (): Promise<GitHubUser[]> => {
  try {
    const response = await blarClient.request(
      "get",
      "/integrations/github/members"
    );
    return response.data.results;
  } catch (error) {
    console.error("Error fetching GitHub members:", error);
    throw error;
  }
};

export const fetchUserInfo = async (): Promise<User> => {
  try {
    const response = await blarClient.request("get", "/users/info");
    return response.data;
  } catch (error) {
    console.error("Error fetching GitHub members:", error);
    throw error;
  }
};
